import Login from './routes/Login';
import Register from './routes/Register';
import Dashboard from './routes/Dashboard';
import Bot from './routes/Bot';
// import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

import UserProvider from './contexts/UserProvider';
import WebsocketContext from './contexts/WebsocketProvider';
import AuthRequired from './hooks/AuthRequired';
import { Container } from './Container';

export const App = () => {
    return (
        <BrowserRouter>
            <UserProvider>
                <Routes>
                    <Route path="/" element={<Container />}>
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                        <Route element={<AuthRequired />}>
                            <Route path="dashboard" element={<Dashboard />} />
                                <Route element={<WebsocketContext />}>
                                    <Route path="bot/:botId" element={<Bot />} />
                                </Route>
                        </Route>
                        <Route path="" element={<Navigate replace to="/dashboard" />} />
                    </Route>
                </Routes>
            </UserProvider>
      </BrowserRouter>
    )
}
