import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'

import { UserContext } from '../../contexts/UserProvider'
import { apiSetNotification } from '../../api/AccountAPI'
import { apiAddGroup, apiDelGroup } from '../../api/TelegramAPI'

const UserNotificationModal = (props, ref) => {
    const { account, checkLogged } = useContext(UserContext)

    const groupInputRef = useRef()

    const [loading, setLoading] = useState(false)
    const [notification, setNotification] = useState(false)
    const [addGroupFlag, setAddGroupFlag] = useState(false)
    const [groupList, setGroupList] = useState([])
    const [groupName, setGroupName] = useState('')

    // GROUP
    const deleteGroup = async (id) => {
        apiDelGroup(id)
        const newList = groupList.filter(obj => obj.id !== id)
        setGroupList(newList)
    }
    const addGroup = async (e=false) => {
        setLoading(true)
         await apiAddGroup(groupName)
        checkLogged()
        setGroupName('')
        setAddGroupFlag(false)
        setLoading(false)
    }

    const addGroupShowInput = (e=false) => {
        setAddGroupFlag(!addGroupFlag)
    }

    const handleGroupChange = async (e) => {
        setGroupName(e.target.value)
    }

    useEffect(() => {
        if (addGroupFlag)
            groupInputRef.current.focus()
    }, [addGroupFlag])

    // PRIVATE
    const handlePrivateChange = async (e) => {
        setNotification(e.target.checked)
        // call api
        const res = await apiSetNotification(e.target.checked)
        setNotification(res)
    }

    const closeModal = (e=false) => {
        ref.current.classList.remove('modal-open')
    }

    useEffect(() => {
        if (account) {
            setNotification(account.notification)
            if (account.telegram)
                setGroupList(account.telegram)
        }
    }, [account])

    return(
        <div className="modal" ref={ref}>
            <div className="modal-box">
                {/* TITLE */}
                <h3 className="font-bold text-lg">
                    <FontAwesomeIcon icon={solid('bell')} size="lg" /> NOTIFICATION
                </h3>

                {/* CONTENT */}
                <div className='flex flex-col py-5 space-y-3 items-center'>
                    <div className='flex pl-10'>
                        <label className='w-24 cursor-pointer label'>
                            <span className='label-text'>PRIVATE </span>
                            <input
                                onChange={handlePrivateChange}
                                defaultChecked={notification}
                                type="checkbox"
                                className={"checkbox" + (notification? " checkbox-primary": " checkbox-secondary border-gray-600")}
                            />
                        </label>
                        <div data-tip="Get private notifications on Telegram from FoxPacket" className='flex tooltip tooltip-secondary text-gray-500'>
                                <FontAwesomeIcon icon={solid('circle-question')} size="lg" className="my-auto ml-3" />
                        </div>
                    </div>

                    <div className='divider'></div>
                    
                    {/* TELEGRAM GROUPS */}
                    <div className='flex'>
                        <h3>TELEGRAM</h3>
                        <div data-tip="Manage telegram groups to get notifications from FoxPacket" className='flex tooltip tooltip-secondary text-gray-500'>
                            <FontAwesomeIcon icon={solid('circle-question')} size="lg" className="my-auto ml-3" />
                        </div>
                    </div>
                    <div className='flex flex-col min-w-[250px] max-w-[450px] min-h-[100px] max-h-[430px] overflow-y-auto'>
                        <div className='flex w-full h-10 bg-gray-700 rounded-lg'>

                            <div className='flex w-10 h-10'>
                                <div onClick={addGroupShowInput} data-tip="ADD GROUP" className='tooltip tooltip-secondary tooltip-right flex w-5 h-5 m-auto rounded-md bg-gray-500 cursor-pointer hover:bg-gray-400 hover:text-white'>
                                    <FontAwesomeIcon icon={solid('plus')} size="1x" className='m-auto' />
                                </div>
                            </div>
                            <div className='flex flex-grow items-center text-center'>
                                {addGroupFlag?
                                <>
                                    <input
                                        ref={groupInputRef}
                                        onChange={handleGroupChange}
                                        value={groupName}
                                        type="text"
                                        className={'input h-8 w-[255px] pr-11 mr-1 placeholder-gray-500' + (loading? " input-disabled":"")}
                                        placeholder='https://t.me/group or @group'
                                    />
                                    <div onClick={() => { addGroup() }} className={'absolute ml-[215px] rounded-lg w-[35px] h-6 btn-accent' + (loading? " btn-disabled": "")}>
                                        <div className='flex w-full h-full'>
                                            {loading?
                                            <FontAwesomeIcon icon={solid('spinner')} size="1x" className='m-auto animate-spin' />
                                            :
                                            <span className='text-xs m-auto'>ADD</span>
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <span className='text-xs w-[255px] pr-16'>GROUPS</span>
                                }
                            </div>
                        </div>

                        <div className="flex flex-col w-full py-3 space-y-2">
                            {/* TELEGRAM GROUP */}
                            {groupList.map((group) => (
                            <div key={group.id} className="flex flex-grow">
                                <div onClick={() => { deleteGroup(group.id) }} data-tip="DELETE" className='tooltip tooltip-right tooltip-secondary group flex w-10 text-green-400 '>
                                    <FontAwesomeIcon icon={solid('circle')} size='sm' className='m-auto transition group-hover:scale-150 group-hover:text-red-500' />
                                </div>
                                <div className='flex flex-grow items-center'>
                                    <span className='max-w-[350px] text-xs truncate'>{group.tg_name}</span>
                                </div>
                            </div>
                            ))}
                        </div>

                    </div>

                </div>

                {/* ACTIONS */}
                <div className="modal-action">
                    {/* <a href="#" className="btn btn-primary">SAVE</a> */}
                    {/* eslint-disable-next-line */}
                    <div onClick={closeModal} className="btn btn-secondary">CLOSE</div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(UserNotificationModal)