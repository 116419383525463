import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import ConfirmModal from '../ConfirmModal'
import TrackingOrderAlarmModal from './TrackingOrderAlarmModal'
import './TrackingOrder.css'

import { getBotOrderTrackingBook, toggleTrackingOrder, cleanTrackingOrder } from '../../api/BotApi'
import { useState, useEffect, useRef, useContext } from 'react'

import { WebsocketContext } from '../../contexts/WebsocketProvider';
import {Calendar} from "primereact/calendar";

// import { useWebsocket } from '../../hooks/useWebsocket'

const TrackingOrder = props => {
    const {
        botId,
        show,
        botData,
    } = props

    const {
        ticker,
        setTicker,
        trackingBook,
        symbolDecimal,
        setTrackingBook,
        isTrackingEnabled,
        setIsTrackingEnabled,
        setPauseTrack } = useContext(WebsocketContext)

    const [tickerHistory, setTickerHistory] = useState(0)

    const toCalendarRef = useRef()
    const askRef = useRef()
    const bidRef = useRef()
    const modalClean = useRef()
    const modalAlarm = useRef()

    // tracking book
    const [trackingBookAsk, setTrackingBookAsk] = useState([])
    const [trackingBookBid, setTrackingBookBid] = useState([])
    const [showTrackingBook, setShowTrackingBook] = useState(false)

    // totals of tracking book
    const [totalBuy, setTotalBuy] = useState(0)
    const [totalSell, setTotalSell] = useState(0)
    const [totalDelta, setTotalDelta] = useState(0)

    const [loading, setLoading] = useState(true)
    const [miniLoading, setMiniLoading] = useState(false)

    const [filterSize, setFilterSize] = useState(0)
    const [filterTime, setFilterTime] = useState(5)
    const [filterDate, setFilterDate] = useState(null)
    const [filterDateCalendar, setFilterDateCalendar] = useState(null)

    const initTrackingOrder = async () => {
        if (!botData)
            return

        if (botData.to) {
            const data = await getBotOrderTrackingBook(botId)
            if (data.length) {
                setTrackingBook(data)
                setIsTrackingEnabled(true)
            }
        }
        else {
            setIsTrackingEnabled(false)
            setLoading(false)
        }
    }

    const toggleOrderTracking = async () => {
        if (loading) return
        setLoading(true)
        const res = await toggleTrackingOrder(botId)
        if (!res) {
            setIsTrackingEnabled(false)
            setTrackingBook([])
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        else {
            setIsTrackingEnabled(true)
        }
    }

    const cleanOrderTracking = async () => {
        setMiniLoading(true)
        modalClean.current.classList.remove('modal-open')
        setTrackingBook([])
        await cleanTrackingOrder(botId)
        setMiniLoading(false)
    }

    // Set size filter from toolbar
    const handleSizeChange = (e) => {
        setFilterSize(Number(e.target.value))
    }

    const handleFilterTimeChange = async (e) => {
        // setMiniLoading(true)
        const filter_time = e.target.value
        setFilterTime(filter_time)
        // const data = await getBotOrderTrackingBook(botId, filter_time)
        // setTrackingBook(data)
        // setMiniLoading(false)
        // setTicker(ticker)
        getTrackingOrderData(filter_time, filterDate)
    }

    const getTrackingOrderData = async (filter_time, filter_date=null) => {
        setMiniLoading(true)
        const data = await getBotOrderTrackingBook(botId, filter_time, filter_date)
        setTrackingBook(data)
        if (filter_date === null) {
            setTicker(ticker)
            // refreshTrackingOrder()
        }
        else {
            // console.log(data.map(o => o._id))
            const min = Math.min(...data.map(o => o._id))
            const max = Math.max(...data.map(o => o._id))
            const avg = Number((min+max)/2).toFixed(symbolDecimal)
            setTickerHistory(avg)
            // setTimeout(() => {
            //     refreshTrackingOrderHistory(avg)
            // }, 100)
        }
        setMiniLoading(false)
    }

    const historyCalendarChange = async (e) => {
        const val = e.value
        if (val !== null) {
            setPauseTrack(true)
            setFilterDateCalendar(val)
            const historyDate = new Date(val).toISOString().split("T")[0]
            setFilterDate(historyDate)
            getTrackingOrderData(filterTime, historyDate)
            // console.log(historyDate)
        }
        else {
            setFilterDateCalendar(null)
            setFilterDate(null)
            setTrackingBook([])
            await getTrackingOrderData(filterTime, null)
            setPauseTrack(false)
            // console.log("clean")
        }
    }

    const refreshTrackingOrder = () => {
        if (loading)
            if (trackingBook.length)
                setLoading(false)

        // Update asks
        setTrackingBookAsk(
          trackingBook
            .filter(level => (level._id >= ticker))
            .sort((a, b) => { return b['_id'] - a['_id'] })
            .filter(level => {
                const filter = filterSize? filterSize:0

                if (level.a >= filter || level.b >= filter)
                    return true
                else
                    return false
            })
            .slice(-100)
        )
        // Update bids
        setTrackingBookBid(
          trackingBook
            .filter(level => (level._id < ticker))
            .sort((a, b) => { return b['_id'] - a['_id'] })
            .filter(level => {
                const filter = filterSize? filterSize:0

                if (level.a >= filter || level.b >= filter)
                    return true
                else
                    return false
            })
            .slice(0,100)
        )
        // Update totals
        setTotalBuy(
          trackingBook.reduce((a,b) => a + b['b'], 0)
        )
        setTotalSell(
          trackingBook.reduce((a,b) => a + b['a'], 0)
        )
        setTotalDelta(
          totalBuy - totalSell
        )
    }

    const refreshTrackingOrderHistory = (_ticker) => {
        if (loading)
            if (trackingBook.length)
                setLoading(false)

        // Update asks
        setTrackingBookAsk(
          trackingBook
            .filter(level => (level._id >= _ticker))
            .sort((a, b) => { return b['_id'] - a['_id'] })
            .filter(level => {
                const filter = filterSize? filterSize:1

                if (level.a >= filter || level.b >= filter)
                    return true
                else
                    return false
            })
            .slice(-100)
        )
        // Update bids
        setTrackingBookBid(
          trackingBook
            .filter(level => (level._id < _ticker))
            .sort((a, b) => { return b['_id'] - a['_id'] })
            .filter(level => {
                const filter = filterSize? filterSize:1

                if (level.a >= filter || level.b >= filter)
                    return true
                else
                    return false
            })
            .slice(0,100)
        )
        // Update totals
        setTotalBuy(
          trackingBook.reduce((a,b) => a + b['b'], 0)
        )
        setTotalSell(
          trackingBook.reduce((a,b) => a + b['a'], 0)
        )
        setTotalDelta(
          totalBuy - totalSell
        )
    }

    // Init tracking order
    useEffect(() => {
        if (botData) {
            /* eslint-disable */
            initTrackingOrder()
        }
    }, [botData])

    // Show / not show TO
    useEffect(() => {
        if (isTrackingEnabled !== null) {
            setShowTrackingBook(isTrackingEnabled)
            if (isTrackingEnabled)
                setLoading(true)
        }
    }, [isTrackingEnabled])

    // Refresh order book TICKER
    useEffect(() => {
        if (filterDate===null) {
            refreshTrackingOrder()
        }
    }, [ticker])
    useEffect(() => {
        if (tickerHistory > 0) {
            refreshTrackingOrderHistory(tickerHistory)
        }
    }, [tickerHistory])

     // Refresh order book FILTER SIZE
    useEffect(() => {
        if (filterDate===null) {
            refreshTrackingOrder()
        }
        else {
            refreshTrackingOrderHistory(tickerHistory)
        }
    }, [filterSize])

    const trackingRowAsk = ({
        index, // Index of row
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        key, // Unique key within array of rendered rows
        parent, // Reference to the parent List (instance)
        style, // Style object to be applied to row (to position it);
    }) => {
        const level = trackingBookAsk[index]

        return (
            <div key={key} style={style}>
                <div className='grid grid-cols-orderbook w-full border-t border-gray-800 hover:bg-slate-800 group'>
                    <span className="text-red-700 w-full group-hover:text-white">{level['_id']}</span>
                    <span className="text-gray-400 text-right group-hover:text-gray-200">{Number(level['b']).toFixed(2)}</span>
                    <span className="text-red-600 text-right group-hover:text-red-500">{Number(level['a']).toFixed(2)}</span>
                    <span className="text-gray-500 text-right group-hover:text-gray-500">{ Number(level['b'] - level['a']).toFixed(2)}</span>
                </div>
            </div>
        )
    }
    const trackingRowBid = ({
        index, // Index of row
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        key, // Unique key within array of rendered rows
        parent, // Reference to the parent List (instance)
        style, // Style object to be applied to row (to position it);
      }) => {
        const level = trackingBookBid[index]

        return (
            <div key={key} style={style}>
                <div className='grid grid-cols-orderbook w-full border-t border-gray-800 hover:bg-slate-800 group'>
                    <span className="text-gray-300 w-full group-hover:text-white">{level['_id']}</span>
                    <span className="text-gray-400 text-right group-hover:text-gray-200">{Number(level['b']).toFixed(2)}</span>
                    <span className="text-red-600 text-right group-hover:text-red-500">{Number(level['a']).toFixed(2)}</span>
                    <span className="text-gray-500 text-right group-hover:text-gray-500">{ Number(level['b'] - level['a']).toFixed(2)}</span>
                </div>
            </div>
        )
    }

    return (
        <div className={show?"flex flex-col bg-slate-900 w-[520px] h-full pl-1 border-l border-gray-700":"w-0 invisible"}>
            {/* Title */}
            <div className="flex w-full h-[36px] bg-gradient-to-br from-base-200 to-slate-900 border-b border-gray-800 relative">
                <span className="text-sm text-center text-gray-300 m-2 whitespace-nowrap w-full">TRACKING ORDER</span>
                <div onClick={toggleOrderTracking} data-tip="POWER" className="tooltip flex px-2 py-1 rounded-md border border-gray-700 text-gray-400 text-center md:hover:bg-gray-700 md:cursor-pointer mr-[2px] m-[2px]">
                    <label className={isTrackingEnabled?'swap swap-active':'swap'}>
                        <FontAwesomeIcon icon={solid('toggle-on')} size="1x" className='m-auto swap-on' />
                        <FontAwesomeIcon icon={solid('toggle-off')} size="1x" className='m-auto swap-off' />
                    </label>
                </div>
            </div>
            
            {/* CONTENT OFF*/}
            {(!showTrackingBook || loading)?
            <div className='flex relative w-[422px] h-[calc(100%-36px)] bg-slate-900 items-center'>
                <div className='absolute w-full h-full bg-grid z-0'></div>
                {loading?
                <div className='btn-loading'>
                    <div className='flex flex-col m-auto text-md'>
                        <FontAwesomeIcon icon={solid('gear')} size="2x" className='animate-spin' />
                    </div>
                </div>
                :
                <div onClick={toggleOrderTracking} className='btn-on-off group'>
                    <div className='flex flex-col m-auto text-md'>
                        <FontAwesomeIcon icon={solid('power-off')} size="lg" />
                        {/* <span className='display text-xs group-hover:hidden'>OFF</span>
                        <span className='hidden text-xs group-hover:flex text-primary'>ON</span> */}
                    </div>
                </div> }
            </div>
            :
            /* CONTENT ON */
            <div className='w-full h-[calc(100%-36px)]'>
                {/* Tool bar */}
                <div className='flex w-full h-[36px] text-gray-400'>
                    <div className='flex w-full'>
                        {/* FILTER SIZE */}
                        <div className='m-[2px]'>
                            <input
                                onChange={handleSizeChange}
                                type="number"
                                placeholder='size'
                                min="0"
                                className='w-28 bg-gray-800 rounded-md p-1 border border-gray-600 text-center focus:outline-none focus:ring-1 focus:ring-yellow-400 md:hover:bg-gray-700'
                            />
                        </div>

                        {/* FILTER TIME */}
                        <div className="m-[4px]">
                            <select onChange={handleFilterTimeChange} defaultValue={"5m"} className="w-16 bg-gray-800 rounded-md p-1 border border-gray-600 text-center md:hover:bg-gray-700 md:cursor-pointer md:font-normal" disabled={miniLoading}>
                                <option value="5m">5m</option>
                                <option value="30m">30m</option>
                                <option value="1H">1H</option>
                                <option value="2H">2H</option>
                                <option value="4H">4H</option>
                                <option value="6H">6H</option>
                                <option value="12H">12H</option>
                                <option value="1D">1D</option>
                            </select>
                        </div>

                    </div>
                    {/* TO History */}
                    <div className="m-[2px]">
                        <Calendar
                          data-tip={"HISTORY"}
                          className={"tooltip tooltip-top" + ((filterDate !== null) && " active")}
                          ref={toCalendarRef}
                          showIcon={true}
                          icon={"pi pi-history"}
                          showButtonBar={true}
                          minDate={new Date(botData.to_start)}
                          maxDate={new Date()}
                          value={filterDateCalendar}
                          // monthNavigator={true}
                          // yearNavigator={false}
                          onChange={e => { historyCalendarChange(e) }}
                        />
                    </div>
                    {/* ALARMS */}
                    <div className="m-[2px]">
                        <div onClick={() => { modalAlarm.current.classList.add('modal-open') }} href="#modal-alarm" data-tip="ALARM" className="tooltip tooltip-left px-2 py-1 rounded-md border border-gray-700 text-gray-400 text-center md:hover:bg-gray-700 md:cursor-pointer">
                            <FontAwesomeIcon icon={solid('bell')} size="1x" />
                        </div>
                    </div>
                    {/* CLEAN */}
                    <div className="m-[2px]">
                        <div onClick={() => { modalClean.current.classList.add('modal-open') }} data-tip="CLEAN" className="tooltip tooltip-left px-2 py-1 rounded-md border border-gray-700 text-gray-400 text-center md:hover:bg-gray-700 md:cursor-pointer">
                            <FontAwesomeIcon icon={solid('trash-can')} size="1x" />
                        </div>
                    </div>
                </div>

                {/* Order book */}
                <div className='flex my-auto h-[calc(100%-36px)] font-normal'>
                    <div className='grid grid-rows-[18px_calc(50%-34px)_50px_calc(50%-34px)] w-full h-full min-h-[300px] my-auto'>
                        {/* Titles */}
                        <div className='w-full text-xs'>
                            <div className='grid grid-cols-orderbook w-full border-t border-gray-800 mr-5'>
                                <span className="text-gray-300 w-full">PRICE</span>
                                <span className="text-gray-300 text-right">BUY</span>
                                <span className="text-gray-300 text-right">SELL</span>
                                <span className="text-gray-300 text-right">DELTA</span>
                            </div>
                        </div>

                        {/* ASK */}
                        <div className='flex w-full text-[13px] relative'>
                            {/* level */}
                            <AutoSizer>
                                {({ height, width }) => (
                                <List
                                    ref={askRef}
                                    className='List'
                                    height={height}
                                    rowCount={trackingBookAsk.length}
                                    rowHeight={16}
                                    rowRenderer={trackingRowAsk}
                                    width={width}
                                    scrollToAlignment="end"
                                    style={filterSize>0? {overflowY: 'scroll'} : {overflowY: 'hidden'}}
                                    scrollToIndex={filterSize>0? -1:trackingBookAsk.length-1}
                                />
                                )}
                            </AutoSizer>
                        </div>

                        {/* PRICE */}
                        <div className='flex w-full h-[50px] relative border-t-2 border-b-2 border-double border-foxblue-800'>
                            {(ticker) &&
                            <div className='flex w-full h-full my-auto'>
                                <div className='flex h-full'>
                                    <div className='my-auto text-xl text-yellow-400'>
                                        {filterDate===null?
                                          Number(ticker).toFixed(symbolDecimal)
                                          :
                                          Number(tickerHistory).toFixed(symbolDecimal)
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-grow'>&nbsp;</div>
                                <div className='grid grid-cols-orderbook-total h-5 text-[13px]  rounded-l-md pl-2 my-auto pr-5'>
                                    {!miniLoading?
                                    <>
                                    <div className='text-gray-600 my-auto pr-2'></div>
                                    <span className="text-gray-200 text-right my-auto">{totalBuy.toFixed(2)}</span>
                                    <span className="text-red-500 text-right my-auto">{totalSell.toFixed(2)}</span>
                                    <span className="text-gray-500 text-right my-auto">{totalDelta.toFixed(2)}</span>
                                    </>
                                    :
                                    <>
                                    <div className='animate-pulse text-gray-500 my-auto pl-4'>
                                        <FontAwesomeIcon icon={solid('cog')} size="2x" className='animate-spin' />
                                    </div>
                                    <span className="animate-pulse w-8 bg-gray-600 rounded-md h-2 my-auto ml-8"></span>
                                    <span className="animate-pulse w-8 bg-gray-600 rounded-md h-2 my-auto ml-8"></span>
                                    <span className="animate-pulse w-8 bg-gray-600 rounded-md h-2 my-auto ml-8"></span>
                                    </> }
                                </div>
                            </div>}
                        </div>

                        {/* BID */}
                        <div className='flex flex-col w-full h-full overflow-y-hidden overflow-x-hidden text-[13px]'>
                            {/* level */}
                            <AutoSizer>
                                {({ height, width }) => (
                                <List
                                    ref={bidRef}
                                    className='List'
                                    height={height}
                                    rowCount={trackingBookBid.length}
                                    rowHeight={16}
                                    rowRenderer={trackingRowBid}
                                    width={width}
                                    style={filterSize>0? {overflowY: 'scroll'} : {overflowY: 'hidden'}}
                                    scrollToIndex={filterSize>0? -1:0}
                                />
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </div>

            </div>
            }


            <TrackingOrderAlarmModal ref={modalAlarm} botData={botData} />
            <ConfirmModal ref={modalClean} onConfirm={() => { cleanOrderTracking() }} title="DELETE TRACKING ORDER" message="Are you sure to delete tracking order?" />
        </div>
    )
}

export default TrackingOrder;