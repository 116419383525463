import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import './Bar.css'

import { ExchangeLogoMatch } from '../../asset/exchanges'
import { useRef, useState } from 'react'

const Bar = props => {
    
    const {
        wallVisible,
        setWallVisible,
        trackingVisible,
        setTrackingVisible,
        botData
    } = props

    const wallRef = useRef()
    const trackingRef = useRef()

    const toggleWall = () => {
        let status = true
        if (wallVisible)
            status = false
        setWallVisible(status)
    }

    const toggleTracking = () => {
        let status = true
        if (trackingVisible)
            status = false
        setTrackingVisible(status)
    }

    return (
        <div className='w-[calc(100%-10px)] h-[36px] text-gray-400 mx-[5px]'>
            {/* BAR */}
            <div className='flex w-full h-full text-gray-200 relative bg-gradient-to-b from-gray-600 to-gray-800 rounded-lg'>
                {/* BOT BAR */}
                <div className="flex flex-grow h-full">
                    {/* BOT ICON */}
                    <div className='flex w-12 bg-gradient-to-br from-foxblue-700 to-foxblue-900 text-center border-r border-gray-700 rounded-l-lg overflow-hidden'>
                        <FontAwesomeIcon icon={solid('robot')} size="xl" className='m-auto pb-1' />
                    </div>

                    {/* CONFIG  */}
                    {/* <div data-tip="CONFIG" class='tooltip tooltip-secondary flex w-5 h-full text-white cursor-pointer hover:bg-primary hover:text-black hover:rounded-r-md'>
                        <FontAwesomeIcon icon={solid('cog')} size="1x" className='m-auto' />
                    </div> */}

                    {/* EXCHANGES */}
                    <div className='flex w-fit h-full'>
                        <div className='flex h-full text-md overflow-hidden'>
                            {/* EXCHANGE */}
                            <div className='flex h-full ml-2'>
                                <div className='flex'>
                                    <div className='exchange-tab'>
                                        <img src={ExchangeLogoMatch[botData.exchange]} alt="exchange" className="w-5 h-5 m-auto rounded-full " />
                                    </div>
{/*                                     
                                    <div className='exchange-tab'>
                                        <img src={BinanceFuturesLogo} alt="exchange" className="w-5 h-5 rounded-full m-auto" />
                                    </div>
                                    <div className='exchange-tab'>
                                        <img src={BithumbLogo} alt="exchange" className="w-5 h-5 rounded-full m-auto" />
                                    </div>
                                    <div className='exchange-tab'>
                                        <img src={BitstampLogo} alt="exchange" className="w-5 h-5 rounded-full m-auto" />
                                    </div>
                                    <div className='exchange-tab'>
                                        <img src={CoinbaseLogo} alt="exchange" className="w-5 h-5 rounded-full m-auto" />
                                    </div>
                                    <div className='exchange-tab'>
                                        <img src={FtxLogo} alt="exchange" className="w-5 h-5 rounded-full m-auto" />
                                    </div>
                                    <div className='exchange-tab'>
                                        <img src={HuobiLogo} alt="exchange" className="w-5 h-5 rounded-full m-auto" />
                                    </div> */}
                                </div>
                            </div>

                            {/* SIZE */}
                            {/* <div className='flex h-full ml-5'>
                                <div className='flex px-1 h-full text-xs'>
                                    <span className='my-auto text-white text-xs'>SIZE</span>
                                </div>
                                <div className='flex px-1'>
                                    <div className='m-auto text-md text-yellow-400'>100</div>
                                </div>
                            </div> */}


                        </div>
                    </div>
                </div>

                {/* TO + Walls buttons */}
                <div className='flex h-full mr-2 space-x-1'>
                    <div ref={trackingRef} onClick={toggleTracking} data-tip="TRACKING ORDER" className={trackingVisible?'tool-button-active':'tool-button'}>
                        <FontAwesomeIcon icon={solid('book-open')} className="m-auto" />
                    </div>
                    <div ref={wallRef} onClick={toggleWall} data-tip="WALLS" className={wallVisible?'tool-button-active':'tool-button'}>
                        <FontAwesomeIcon icon={solid('list')} className="m-auto" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Bar;