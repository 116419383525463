import BinanceLogo from './binance.svg'
import BinanceFuturesLogo from './binance_futures.svg'
import BithumbLogo  from './bithumb.svg'
import BitstampLogo  from './bitstamp.svg'
import CoinbaseLogo  from './coinbase.svg'
import FtxLogo  from './ftx.svg'
import HuobiLogo  from './huobi.svg'

export {
    BinanceLogo,
    BinanceFuturesLogo,
    BithumbLogo,
    BitstampLogo,
    CoinbaseLogo,
    FtxLogo,
    HuobiLogo,
}
export const ExchangeLogoMatch = {
    BINANCE: BinanceLogo,
    BINANCE_FUTURES: BinanceFuturesLogo
}