import { Link, Outlet } from 'react-router-dom';
import logo from './asset/logo.png';
import UserMenu from './components/user/UserMenu';


export const Container = () => {
    return (
        <div data-theme='foxpacket' className="flex flex-col h-screen">
            {/* Header */}
            <div className='flex w-full bg-gray-900 h-[65px]'>
                <UserMenu />
                <Link to="/login" className='mx-auto'>
                    <img src={logo} className="w-60 p-2 grayscale" alt="foxpacket-logo" />
                </Link>
            </div>

            {/* Content / Routes */}
            <div className='flex m-auto w-full h-[calc(100%-130px)] relative'>
                <div className='absolute w-full h-full bg-grid -z-10'></div>
                <Outlet />
            </div>

            {/* Footer */}
            <div className='w-full h-[65px] bg-gray-900 py-5 hidden md:flex'>
                <span className='mx-auto text-gray-300 text-xs'>© {new Date().getFullYear()} Copyright FoxPacket Networks ( Sl0vtski ) </span>
            </div>
        </div>
    );
}
