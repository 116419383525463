import { api_get, api_post } from "./request";

export const apiGetAccount = async () => {
    const res = await api_get('/account/')

    if (res.ok) {
        const data = await res.json()
        return data
    }
    else {
        return false
    }
}

export const apiChangePassword = async (password) => {
    const params = {
        password: password
    }
    const res = await api_post('/account/set_password/', params)

    if (res.ok) {
        const data = await res.json()
        return data
    }
    else {
        return false
    }
}

export const apiSetNotification = async (notification) => {
    const params = {
        notification: notification
    }
    const res = await api_post('/account/set_notification/', params)

    if (res.ok) {
        const data = await res.json()
        return data
    }
    else {
        return false
    }
}