import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import React from 'react'
import { useRef, useState, useContext } from 'react'
import { apiGetBotTokenStatus, apiSetBotToken } from '../../api/TelegramAPI'

import { UserContext } from '../../contexts/UserProvider'


const UserTelegramSetup = (props, ref) => {
    const { checkLogged } = useContext(UserContext)

    const steps = {
        step1: useRef(),
        step2: useRef(),
        step3: useRef()
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [currentStep, setCurrentStep] = useState(1)
    const [tokenValue, setTokenValue] = useState('')
    const [hashValue, setHashValue] = useState('')

    const back = (e) => {
        setCurrentStep(1)
        setLoading(false)
    }
    const next = async (e=false, step=false) => {
        setError('')
        if (!step)
            step = currentStep + 1
        
        if (step === 2) {
            setLoading(true)
            const data = await apiSetBotToken(tokenValue)

            if (data.error) {
                // show error
                console.error('no data:', data)
                setError(data.error)
                setLoading(false)
                return
            }
            else {
                console.log(data)
                setHashValue(data.activate_token)
                waitStatus()
                return
            }
        }

        if (step > 3)
            step = 3
        setCurrentStep(step)
    }

    const waitStatus = async () => {
        setCurrentStep(2)  // FIXME
        const data = await apiGetBotTokenStatus()
        console.log("waitStatus:", data)
        if (!data) {
            setTimeout(waitStatus, 2000)
        }
        else{
            setLoading(false)
            next(false, 3)
        }
    }

    const closeModal = (e=false) => {
        checkLogged()
        ref.current.classList.remove('modal-open')
        setCurrentStep(1)
        setTokenValue('')
        setHashValue('')
    }

    return (
        <div className="modal" ref={ref}>
            <div className="modal-box">
                {/* TITLE */}
                <h3 className="font-bold text-lg">
                    <FontAwesomeIcon icon={brands('telegram')} size="lg" /> &nbsp; TELEGRAM SETUP
                </h3>

                {/* CONTENT */}
                <div className='flex flex-col w-full mt-4 space-y-8'>
                    <ul className="w-full steps steps-horizontal">
                        <li className="step step-primary">TOKEN</li>
                        <li className={"step" + (currentStep>1?" step-primary":"")}>CONFIRM</li>
                        <li className={"step" + (currentStep>2?" step-primary":"")}>FINISH</li>
                    </ul>

                    {/* STEP 1 */}
                    <div ref={steps.step1} className={'flex flex-col w-full space-y-5' + (currentStep===1?"":" hidden")}>
                        <p className='bg-secondary text-white p-4 rounded-lg text-center'>PASTE TELEGRAM BOT TOKEN</p>
                        <input
                            onChange={(e) => { setError(''); setTokenValue(e.target.value) }}
                            className='input input-bordered'
                            type="text"
                            placeholder='TOKEN'
                        />
                    </div>

                    {/* STEP 2 */}
                    <div ref={steps.step2} className={'flex flex-col w-full space-y-5' + (currentStep===2?"":" hidden")}>
                        <p className='bg-secondary text-white p-4 rounded-lg text-center'>SEND THIS COMMAND TO THE BOT</p>
                        <div className='flex flex-col w-full'>
                            <input
                                onClick={(e) => { e.target.select() }}
                                className='input input-bordered text-gray-300'
                                type="text"
                                readOnly={true}
                                value={'/activate ' + hashValue}
                            />
                        </div>
                    </div>

                    {/* STEP 3 */}
                    <div ref={steps.step3} className={'flex flex-col w-full space-y-5' + (currentStep===3?"":" hidden")}>
                        <div className='flex flex-col space-y-6 bg-secondary text-white p-4 rounded-lg text-center'>
                            <FontAwesomeIcon icon={solid('circle-check')} size="3x" className='text-green-400' />
                            <span>TELEGRAM SETUP COMPLETE</span>
                        </div>
                    </div>
                </div>

                {error?
                    <div onClick={() => { setError('') }} className="absolute w-[260px] left-1/4 bottom-5 alert shadow-lg alert-error">
                        <FontAwesomeIcon icon={solid('circle-xmark')} />
                        <div className='mx-auto'>{error}</div>
                    </div> : null }

                <div className="modal-action">
                    {currentStep===1?
                    <>
                        <div onClick={closeModal} className="btn btn-secondary">CANCEL</div>
                        <div className='flex-grow'></div>
                    </>
                    : null }
                    {currentStep===2?
                    <>
                        <div onClick={back} className="btn btn-secondary">BACK</div>
                        <div className='flex-grow'></div>
                    </>
                    : null }
                    {currentStep===3?
                    <div onClick={closeModal} className="btn btn-secondary">CLOSE</div>
                    :
                        loading?
                        <div onClick={next} className="btn btn-disabled text-gray-300 w-20">
                            <FontAwesomeIcon icon={solid('spinner')} size="1x" className='animate-spin' />
                        </div>
                        :
                        <div onClick={next} className={"w-20" + (tokenValue.length<40? " btn btn-disabled":" btn btn-primary")}>NEXT</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(UserTelegramSetup)