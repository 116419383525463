import { api_get, api_post, api_delete } from "./request";

export const apiGetBotTokenStatus = async () => {
    const res = await api_get('/telegram/token/')

    if (res.ok) {
        const data = await res.json()
        return data
    }
    else
        return false
}

export const apiSetBotToken = async (token) => {
    const params = {
        token: token
    }
    const res = await api_post('/telegram/token/', params)

    if (res.ok) {
        const data = await res.json()
        return data
    }
    else
        return false
}

export const apiAddGroup = async (name) => {
    const params = {
        notification: true,
        name: name
    }
    const res = await api_post('/telegram/group/', params)
    
    if (res.ok) {
        const data = await res.json()
        return data
    }
    else
        return false
}

export const apiDelGroup = async (id) => {
    const params = {
        id: id
    }
    const res = await api_delete(`/telegram/group/${id}/`, params)
    
    if (res.ok) {
        return true
    }
    else
        return false
}