import React from 'react'
import { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { createOrderTrackingAlarm, deleteOrderTrackingAlarm } from '../../api/BotApi'

import { UserContext } from '../../contexts/UserProvider'

const TrackingOrderAlarmModal = (props, ref) => {
    const { botData } = props

    const { account } = useContext(UserContext)

    const [updated, setUpdated] = useState(false)
    const [alarm, setAlarm] = useState(false)
    const [alarmData, setAlarmData] = useState({
        bid: 0,
        ask: 0,
        time: 300
    })

    const createAlarm = async (e) => {
        if (
            (botData.alert.bid === alarmData.bid) &&
            (botData.alert.ask === alarmData.ask) &&
            (botData.alert.time === alarmData.time)
        ) {
            ref.current.classList.remove('modal-open')
        }
        else {
            const res = await createOrderTrackingAlarm(botData.id, alarmData.time, alarmData.bid, alarmData.ask)
            if (res) {
                setAlarm(true)
                // Close modal
                ref.current.classList.remove('modal-open')
                updateInputData(false)
            }
        }
        setUpdated(true)
    }

    const deleteAlarm = async (e) => {
        deleteOrderTrackingAlarm(botData.id)
        setAlarmData({
            bid: 0,
            ask: 0,
            time: 300
        })
        setAlarm(false)
        setUpdated(true)
    }

    const handleChange = (e) => {
        setAlarmData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const closeModal = (e=false) => {
        ref.current.classList.remove('modal-open')
        updateInputData(!updated)
        setUpdated(false)
    }

    const updateInputData = async (from_bot=true) => {
        let obj = {}
        if (from_bot)
            obj = botData.alert
        else
            obj = alarmData
        
        if (obj.bid || obj.ask) {
            const { bid, ask, time } = obj
            setAlarmData((prev) => ({
                ...prev,
                'bid': bid,
                'ask': ask,
                'time': time
            }))
            setAlarm(true)
        }
        else {
            setAlarm(false)
        }
    }

    useEffect(() => {
        if (botData) {
            updateInputData()
        }
    }, [botData])

    return (
        <div className="modal" ref={ref} onClick={() => { if (!account.telegram) closeModal() }}>
            <div className="modal-box overflow-hidden">
                {!account.telegram?
                <div className='absolute z-10 w-full h-full'>
                    <div className='flex w-full h-full pb-10'>
                        <div className='flex m-auto px-5 bg-accent rounded-lg h-[80px] shadow-lg border border-neutral'>
                            <span className='m-auto text-white font-semibold'>PLEASE SET UP YOUR NOTIFICATION SETTINGS</span>
                        </div>
                    </div>
                </div> : null}
                <div className={account.telegram?'':'blur-sm'}>
                    <h3 className="font-bold text-lg">ALARMS</h3>
                    <div className="flex flex-col mt-4 py-4 mx-auto w-fit space-y-2">
                        <label className="input-group input-group">
                            <span className="w-24 bg-secondary">TIME</span>
                            <select onChange={handleChange} name='time' defaultValue={alarmData.time} className="select select-bordered w-[249px]">
                                <option value="300">5m</option>
                                <option value="1800">30m</option>
                                <option value="3600">1H</option>
                                <option value="14400">4H</option>
                            </select>
                        </label>
                        <label className="input-group input-group">
                            <span className="w-24 bg-secondary">BUY</span>
                            <input
                                onFocus={(e) => { e.target.select() }}
                                onChange={handleChange}
                                name='bid'
                                type="number"
                                value={alarmData.bid}
                                min={0}
                                placeholder="0"
                                className="input input-bordered"
                            />
                        </label>
                        <label className="input-group input-group">
                            <span className="w-24 bg-secondary">SELL</span>
                            <input
                                onFocus={(e) => { e.target.select() }}
                                onChange={handleChange}
                                name='ask'
                                type="number"
                                value={alarmData.ask}
                                min={0}
                                placeholder="0"
                                className="input input-bordered"
                            />
                        </label>
                        <div className="mt-8 alert shadow-lg alert-error hidden">
                            <FontAwesomeIcon icon={solid('circle-xmark')} />
                            <div className='mx-auto'>Size is too small</div>
                        </div>
                    </div>
                    <div className="modal-action">
                        <div className='flex w-48 my-auto'>
                            <span className='my-auto'>STATUS</span>
                            <div className='flex ml-10'>
                                {/* <FontAwesomeIcon icon={solid('circle')} size='sm' className='m-auto mr-2 text-green-400' /> */}
                                {alarm?
                                <>
                                <div onClick={deleteAlarm} data-tip="DELETE" className='tooltip tooltip-secondary m-auto group flex w-10 text-green-400 cursor-pointer'>
                                    <FontAwesomeIcon icon={solid('circle')} size='sm' className='m-auto transition group-hover:scale-150 group-hover:text-red-500' />
                                </div>
                                <span className='my-auto'>ACTIVE</span>
                                </>
                                :
                                <>
                                <FontAwesomeIcon icon={solid('circle')} size='sm' className='m-auto mr-2 text-gray-500' />
                                <span className='my-auto'>OFF</span>
                                </>
                                }
                            </div>
                        </div>
                        {(alarmData.bid>0 || alarmData.ask>0)?
                        <div onClick={createAlarm} className="btn btn-primary">SAVE</div>
                        :
                        <div className="btn btn-disabled">SAVE</div>
                        }
                        <div name="close" onClick={closeModal} className="btn btn-secondary">CLOSE</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(TrackingOrderAlarmModal);