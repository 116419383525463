import { Link, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useContext, useState } from 'react';
import { UserContext } from '../contexts/UserProvider';

const Register = props => {
    const { register, error, cleanError, isLogged } = useContext(UserContext)

    const [ userData, setUserData ] = useState({
        username: '',
        password: '',
        password_r: ''
    })

    const handleChange = (e) => {
        cleanError()
        setUserData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    if (isLogged)
        return <Navigate to='/dashboard' />
    else
    return (
        <div className='m-auto'>
            <div className="card w-96 bg-slate-900 shadow-xl">
                <div className='card-body items-center p-10'>
                    <h2 className="card-title">
                        <FontAwesomeIcon icon={solid('user-plus')} className='mr-2' />
                        REGISTER
                    </h2>
                    
                    <input
                        name='username'
                        type="text"
                        placeholder="USERNAME"
                        className="input w-full my-2"
                        onChange={handleChange}
                    />
                    <input
                        name='password'
                        type="password"
                        placeholder="PASSWORD"
                        className="input w-full my-2"
                        onChange={handleChange}
                    />
                    <input
                        name='password_r'
                        type="password"
                        placeholder="REPEAT PASSWORD"
                        className="input w-full my-2"
                        onChange={handleChange}
                    />

                    { error?
                    <div className="alert shadow-lg bg-red-800">
                        <FontAwesomeIcon icon={solid('circle-xmark')} />
                        <div className='mx-auto'>{error}</div>
                    </div> : null }
                    
                    <div className='card-actions relative'>
                        <div className="btn btn-primary" onClick={() => { register(userData) }}>REGISTER</div>
                        <div className='absolute w-full -bottom-9 text-center'>
                            <Link to='/login' className='text-gray-600 md:hover:text-gray-500'>
                                Go back
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register;