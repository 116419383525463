import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import ConfirmModal from '../ConfirmModal'
import { useContext, useEffect, useState, useRef } from 'react'

import { WebsocketContext } from '../../contexts/WebsocketProvider';

import { filledDownload, filledDelete } from '../../api/BotApi'

const WallInfo = props => {
    const { symbolDecimal } = useContext(WebsocketContext)

    const modalClean = useRef()
    const wallListAskRef = useRef()
    const { botData, botId } = props

    const [wallListAsk, setWallListAsk] = useState([])
    const [wallListBid, setWallListBid] = useState([])
    const [filledList, setFilledList] = useState([])
    const [filterTime, setFilterTime] = useState(0)

    const time_filters = {
        '': 0,
        '5m': 5,
        '30m': 30,
        '1H': 60,
        '2H': 120,
        '4H': 240,
        '1D': 1440
    }

    const downloadFilled = async () => {
        console.log(botData)
        const blob = await filledDownload(botId)
        const url = window.URL.createObjectURL(blob)
        var a = document.createElement('a');
        a.href = url;
        a.download = `Foxpacket_FW_${botData.symbol.replace('/', '')}_${new Date().getTime()}.csv`;
        a.target = '_blank'
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again   
    }

    const cleanFilledWalls = async () => {
        modalClean.current.classList.remove('modal-open')
        await filledDelete(botId)
        setFilledList([])
    }

    const handleFilterTimeChange = async (e) => {
        const filter = time_filters[e.target.value]*60*1000
        setFilterTime(filter)
    }

    useEffect(() => {
        if (wallListAsk.length) {
            wallListAskRef.current.scrollTop = wallListAskRef.current.scrollHeight;
        }
    }, [wallListAsk])

    useEffect(() => {
        if (botData) {
            setWallListAsk(botData.order_book.asks)
            setWallListBid(botData.order_book.bids)
            setFilledList(botData.filled_walls)
        }
    }, [botData])

    return (
        <div className={props.show?"flex flex-col h-full min-w-[200px] pl-1 bg-slate-900 border-l border-gray-700":"w-0 invisible"}>
            {/* Title */}
            <div className="flex w-full h-[36px] bg-gradient-to-br from-base-200 to-slate-900 border-b border-gray-800 relative">
                <span className="text-sm text-center text-gray-300 m-2 whitespace-nowrap w-full">WALLS</span>
            </div>
            <div className='flex flex-col w-full h-[calc(100%-36px)] my-auto'>
                {/* ACTIVE LIST */}
                <div className="w-full flex-col h-1/2 overflow-hidden">
                    {/* Main title */}
                    <div className="flex w-full h-[36px] border-b border-gray-800">
                        <div className="my-auto text-xs badge badge-ghost badge-md">ACTIVE</div>
                        <div className="my-auto flex-grow"></div>
                        <div className="my-auto text-xs badge badge-ghost badge-md mr-2"><span className='text-yellow-primary'>{botData.size}</span></div>
                    </div>

                    {/* Titles */}
                    <div className="flex w-full h-[18px] border-b border-gray-800">
                        <div className="flex-grow text-xs text-gray-200">PRICE</div>
                        <div className="mr-4 text-xs text-gray-200">SIZE</div>
                    </div>

                    {/* Book */}
                    <div className="flex flex-col h-[calc(100%-54px)]">
                        {/* ASK */}
                        <div className="flex relative h-[calc(50%-2px)] font-normal">
                            <div ref={wallListAskRef} className="flex flex-col w-full absolute bottom-0 overflow-y-scroll overflow-x-hidden max-h-full">
                                {/* level */}
                                {wallListAsk.sort((a, b) => { return b[0] - a[0] })
                                .map(level => (
                                <div key={level[0]} className='flex text-xs w-full border-t border-gray-800 hover:bg-slate-800'>
                                    <span className="text-foxred-600 hover:text-foxred-500 flex-grow">{Number(level[0]).toFixed(symbolDecimal)}</span>
                                    <span className="text-gray-600 mr-1">{Number(level[1]).toFixed(2)}</span>
                                </div>
                                ))}
                            </div>
                        </div>

                        <div className='w-full h-[4px] bg-gray-800'></div>

                        {/* BID */}
                        <div className="h-[calc(50%-2px)] font-normal overflow-y-scroll">
                            {/* level */}
                            {wallListBid.sort((a, b) => { return b[0] - a[0] })
                            .map(level => (
                            <div key={level[0]} className='flex text-xs w-full border-t border-gray-800 hover:bg-slate-800'>
                                <span className="text-gray-400 hover:text-white flex-grow">{Number(level[0]).toFixed(symbolDecimal)}</span>
                                <span className="text-gray-600 mr-1">{Number(level[1]).toFixed(2)}</span>
                            </div>
                            ))}
                        </div>
                    </div>

                </div>

                {/* FILLED LIST */}
                <div className="w-full flex-col h-1/2 overflow-hidden">
                    {/* Main title */}
                    <div className="flex w-full h-[50px] border-b border-t border-gray-800">
                        <div className="my-auto text-xs badge badge-ghost badge-md">FILLED</div>

                        {/* Tool bar */}
                        <div className="flex ml-auto my-auto">
                            {/* FILTER TIME */}
                            <div className="my-auto mr-1">
                                <select onChange={handleFilterTimeChange} defaultValue={"0"} className="w-12 bg-gray-800 rounded-md p-1 text-gray-400 border border-gray-600 text-center md:hover:bg-gray-700 md:cursor-pointer md:font-normal">
                                    <option value="0">ALL</option>
                                    <option value="5m">5m</option>
                                    <option value="30m">30m</option>
                                    <option value="1H">1H</option>
                                    <option value="2H">2H</option>
                                    <option value="4H">4H</option>
                                    <option value="1D">1D</option>
                                </select>
                            </div>

                            {/* DOWNLOAD FILLED */}
                            <div className="mx-[2px]">
                                <div onClick={() => { downloadFilled() }} data-tip="DOWNLOAD" className="tooltip tooltip-left px-2 py-[2px] rounded-md border border-gray-700 text-gray-400 text-center md:hover:bg-gray-700 md:cursor-pointer">
                                    <FontAwesomeIcon icon={solid('download')} size="1x" />
                                </div>
                            </div>

                            {/* CLEAN FILLED */}
                            <div className="mx-[2px]">
                                <div onClick={() => { modalClean.current.classList.add('modal-open') }} data-tip="CLEAN" className="tooltip tooltip-left px-2 py-[2px] rounded-md border border-gray-700 text-gray-400 text-center md:hover:bg-gray-700 md:cursor-pointer">
                                    <FontAwesomeIcon icon={solid('trash-can')} size="1x" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Titles */}
                    <div className="flex w-full h-[18px] border-b border-gray-800">
                        <div className="flex-grow text-xs text-gray-200">PRICE</div>
                        <div className="mr-4 text-xs text-gray-200">SIZE</div>
                    </div>

                    {/* Book */}
                    <div className="h-[calc(100%-68px)] font-normal overflow-y-auto">
                        {/* level */}
                        {filledList
                        .filter(filled => {
                            if (filterTime) {
                                const filled_date = new Date(filled.date+"Z").getTime()
                                const now = new Date().getTime()
                                const filter_check = now - filterTime
                                if (filled_date >= filter_check)
                                    return true
                                else
                                    return false
                            }
                            else
                                return true
                        })
                        .sort((a, b) => { return new Date(b.date).getTime() - new Date(a.date).getTime() })
                        .map(filled => (
                        <div key={filled.id} className='flex text-xs w-full border-t border-gray-800 hover:bg-slate-800'>
                            <span className={(filled.side === 'ask')? "flex-grow text-foxred-600 hover:text-foxred-500":"flex-grow text-gray-400 hover:text-white"}>{Number(filled.price).toFixed(symbolDecimal)}</span>
                            <span className="text-gray-600 mr-1">{Number(filled.filled).toFixed(2)}</span>
                        </div>
                        ))}
                    </div>
                </div>
            </div>

            <ConfirmModal ref={modalClean} onConfirm={() => { cleanFilledWalls() }} title="DELETE FILLED WALLS" message="Are you sure to delete filled walls?" />
        </div>
    )
}

export default WallInfo;