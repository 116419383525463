import { useContext, useEffect, useState } from 'react'
import { getBotMarks } from '../api/BotApi'
import { WebsocketContext } from '../contexts/WebsocketProvider';

const api_request_binance = async (path, params="") => {
	const endpoint = "https://api.binance.com";

	if (params)
		params = new URLSearchParams(params).toString()
	
	const url = endpoint + path + (params?`?${params}`:'');
	try
	{
		//console.log(`[api_request]: Fetching ${url}`);
		const response = await fetch(url);
		return response.json();
	}
	catch (error)
	{
		throw new Error(`** ERROR [api_request]: ${error.status}`);
	}
}

export const useDatafeed = () => {
    const {
        botId,
        symbolDecimal,
        exchange,
        sub_kline,
        unsub_kline,
        setKlineCallback } = useContext(WebsocketContext)

    const last_bars_cache = new Map();
    const [channelSub, setChannelSub] = useState(null)
    const subId = {}

    const resolution_map = {
        1: '1m',
        3: '3m',
        5: '5m',
        15: '15m',
        30: '30m',
        60: '1h',
        120: '2h',
        240: '4h',
        360: '6h',
        720: '12h',
        '1D': '1d',
        '1W': '1w',
    }

    const configuration_data = {
        supported_resolutions: ['1', '5', '15', '30', '60', '240', '360', '1D'],
        supports_marks: true,
    };

    const socket_on_msg = async (data) => {
        if (data.e !== "kline")
            return;
        
        const subscription_item = channelSub
        if (subscription_item === undefined)
        {
            return;
        }

        const last_daily_bar = subscription_item.last_daily_bar;
        let bar = {
            ...last_daily_bar,
            time: data.k.t,
            close: parseFloat(data.k.c),
            open: parseFloat(data.k.o),
            high: Math.max(data.k.h),
            low: Math.min(data.k.l),
            volume: data.k.v,
        }
        
        subscription_item.last_daily_bar = bar;

        // send data to every subscriber of that symbol
        subscription_item.handlers.forEach(handler => handler.callback(bar))

    }

    const onReady = (callback) => {
        setTimeout(function(){
            callback(configuration_data)
        })
    }

    const resolveSymbol = async (symbol_name,
                                 on_resolved_callback,
                                 on_error_callback) => {

        const symbol_decimal = 0.01

        const symbol_info = {
            exchange: exchange,
            ticker: symbol_name,
            name: symbol_name,
            description: symbol_name,
            type: 'crypto',
            session: '24x7',
            timezone: 'Etc/UTC',
            //format: "price",
            minmov: 1,
            pricescale: Math.pow(10, symbolDecimal),
            has_intraday: true,
            has_no_volume: true,
            has_weekly_and_monthly: true,
            supported_resolutions: configuration_data.supported_resolutions,
            volume_precision: symbol_decimal,
            data_status: 'streaming',
        }

        on_resolved_callback(symbol_info);
    }

    const getBars = async (symbol_info,
                            resolution,
                            period,
                            on_history_callback,
                            on_error_callback,
                            first_data_request) => {
        // Binance conversion
        resolution = resolution_map[resolution];

        const params = {
            symbol: symbol_info.name.replace('/', ''),
            interval: resolution,
            //startTime: period['from']*1000,
            endTime: period['to']*1000,
            limit: 1000
        }

        if (symbol_info.resolution === resolution) {
            params['endTime'] = symbol_info.first_bar_time;
        }
        else{
        }
        
        try {
            const data = await api_request_binance('/api/v3/klines', params);
            
            if (data.length === 0) {
                on_history_callback([], {noData: true});
                return;
            }
            else {
            }

            let bars = [];
            data.forEach(bar => {
                bars = [...bars, {
                    time: parseFloat(bar[0]),
                    low: parseFloat(bar[3]),
                    high: parseFloat(bar[2]),
                    open: parseFloat(bar[1]),
                    close: parseFloat(bar[4]),
                    volume: parseFloat(bar[5]),
                }]
            })
            symbol_info.first_bar_time = bars[0]['time'];
            symbol_info.resolution = resolution;

            last_bars_cache[symbol_info.full_name] = bars[bars.length-1];
            on_history_callback(bars, {noData: true});
        }
        catch (error) {
            on_error_callback(error);
        }
    }

    const subscribeBars = (symbol_info,
                            resolution,
                            on_realtime_callback,
                            subscribe_uid,
                            on_reset_cache_callback) => {
        
        const last_daily_bar = last_bars_cache.get(symbol_info.full_name)
        // Binance conversion
        resolution = resolution_map[resolution];
        resolution = resolution.toLowerCase()

        const handler = {
            id: subscribe_uid,
            callback: on_realtime_callback
        }

        let subscription_item = channelSub
        if (subscription_item) {
            subscription_item.handlers.push(handler);
            return;
        }

        subscription_item = {
            subscribe_uid,
            resolution,
            last_daily_bar,
            handlers: [handler]
        };
        subId[subscribe_uid] = subscription_item
        setChannelSub(subscription_item)
    }

    const unsubscribeBars = (subscribe_uid) => {
        console.log("unsubscribeBars() subscribe_uid:", subscribe_uid)
        unsub_kline(subId[subscribe_uid].resolution)
        return
    }

    const getMarks = async (symbol_info, from, to, on_data_callback, resolution) => {
        // Get marks from api
        // console.log('getMarks resolution:', symbol_info)
        let data = await getBotMarks(botId, resolution, from, to);
        on_data_callback(data);
    }

    /* eslint-disable */
    useEffect(() => {
        console.log("channelSub:", channelSub)
        if (channelSub !== null) {
            if (channelSub) {
                setKlineCallback(() => socket_on_msg)
                sub_kline(channelSub.resolution)
            }
            else {
                // unsub_kline()
            }
        }
    }, [channelSub])

    /* eslint-disable */
    return {
        socket_on_msg,
        onReady,
        resolveSymbol,
        getBars,
        subscribeBars,
        unsubscribeBars,
        getMarks,
    };
}

