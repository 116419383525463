import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import UserProfileModal from './UserProfileModal'
import UserNotificationModal from './UserNotificationModal'
import { useContext, useRef } from 'react'
import { UserContext } from '../../contexts/UserProvider'
import UserTelegramSetup from './UserTelegramSetup'

const UserMenu = props => {
    const profileModalRef = useRef()
    const dropdownRef = useRef()
    const notificationModalRef = useRef()
    const telegramModalRef = useRef()

    const { account, isLogged, logout } = useContext(UserContext)

    const openProfileModal = (e) => {
        dropdownRef.current.blur()
        profileModalRef.current.classList.add('modal-open')
    }

    const openNotificationModal = (e=false) => {
        if (account.telegram) {
            notificationModalRef.current.classList.add('modal-open')
        }
        else {
            telegramModalRef.current.classList.add('modal-open')
        }
    }
    
    if (!isLogged)
    return null
    else
    return (
        <div className='absolute top-2 right-4 md:cursor-pointer'>
            <div className="dropdown dropdown-end" ref={dropdownRef}>
                <label tabIndex="0" className="btn rounded-full hover:bg-gray-600">
                    <FontAwesomeIcon icon={solid('user')} size="xl" className="align-middle" />
                </label>
                <ul tabIndex="0" className="menu menu-compact dropdown-content bg-base-200 rounded-md w-52 shadow-xl">
                    <li>
                        <div onClick={openProfileModal}>
                            <FontAwesomeIcon icon={solid('user-gear')} size="lg" /> PROFILE
                        </div>
                    </li>
                    <li>
                        {/* eslint-disable-next-line */}
                        <a onClick={openNotificationModal}>
                            <FontAwesomeIcon icon={solid('bell')} size="lg" /> NOTIFICATIONS
                        </a>
                    </li>
                    <li className="border-t border-gray-500">
                        {/* eslint-disable-next-line */}
                        <a onClick={()=>{ logout() }}>
                            <FontAwesomeIcon icon={solid('right-from-bracket')} size="lg" /> LOGOUT
                        </a>
                    </li>
                </ul>
            </div>

            <UserProfileModal ref={profileModalRef} />
            <UserNotificationModal ref={notificationModalRef} />
            <UserTelegramSetup ref={telegramModalRef} />
        </div>
    )
}

export default UserMenu; 