import { createContext, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom';
import {WS_HOST} from '../api/request'

let ws;

export const WebsocketContext = createContext()

export const WebsocketProvider = (props) => {
    // ID of bot from url
    const { botId } = useParams()

    const [symbolDecimal, setSymbolDecimal] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isTrackingEnabled, setIsTrackingEnabled] = useState(null)
    const [pauseTrack, setPauseTrack] = useState(false)

    const [exchange, setExchange] = useState(false)
    const [symbol, setSymbol] = useState(false)
    const [ticker, setTicker] = useState(0)
    // Tracking book live
    const [trackingBook, setTrackingBook] = useState([])

    // Streams that websocket will connect
    const [stream, setStream] = useState([])
    // Callback for tradingview klines
    const [klineCallback, setKlineCallback] = useState(false)
    // Active kline channel
    const [klineChannel, setKlineChannel] = useState(null)

    const exchange_ws_url = {
        BINANCE: 'wss://stream.binance.com:9443/ws',
        BINANCE_FUTURES: 'wss://stream.binance.com:9443/ws',
    }

    const subscribe = async (params) => {
        if (isOpen) {
            // console.log('>> send to ws')
            ws.send(JSON.stringify(params))
        }
        else {
            // console.log('>> add to queue', stream, params)
            setStream([...stream, params])
        }
    }

    const unsubscribe = async (params) => {
        subscribe(params)
    }

    const sub_init = async() => {
       // ticker
       sub_ticker()
    }

    const sub_ticker = async () => {
        const ticker_params = {
            method: "SUBSCRIBE",
            exchange: exchange,
            params: `${symbol.toLowerCase()}@ticker`,
        };
        subscribe(ticker_params)
        //ws.send(JSON.stringify(params))
    }

    const sub_aggtrade = async () => {
        const aggtrade_params = {
            method: "SUBSCRIBE",
            exchange: exchange,
            params: `${symbol.toLowerCase()}@aggtrade`,
        };
        subscribe(aggtrade_params)
        //ws.send(JSON.stringify(params))
    }

    const sub_kline = async (resolution='30m') => {
        // console.log('-- SUB KLINE --')
        // resolution = resolution_map[resolution];
        // resolution = resolution.toLowerCase()

        const kline_channel = (`${symbol.toLowerCase()}@kline_${resolution}`)
        setKlineChannel(kline_channel)
        const kline_params = {
            method: "SUBSCRIBE",
            exchange: exchange,
            params: kline_channel,
        };
        subscribe(kline_params)
    }

    const unsub_kline = async (resolution) => {
        const kline_channel = (`${symbol.toLowerCase()}@kline_${resolution}`)

        const kline_params = {
            method: "UNSUBSCRIBE",
            exchange: exchange,
            params: kline_channel,
        }

        unsubscribe(kline_params)
    }

    const on_msg = async (msg) => {
        const m = JSON.parse(msg.data)
        // TradingView
        if (m.e.includes('kline')) {
            kline_callback(m['data'])
        }
        // Tracking Order
        else if (m.e === 'aggtrade')
            aggtrade_callback(m)
        // Price
        else if (m.e === 'ticker')
            ticker_callback(m)
        else {
            console.warn('** ws event:', m)
        }
    }

    const kline_callback = async(data) => {
        if (klineCallback)
            klineCallback(data)
    }

    const ticker_callback = async (data) => {
        setTicker(data['p'])
    }

    const aggtrade_callback = async (data) => {
        const price = parseFloat(data['p']);
        const qty = parseFloat(data['q']);
        const buy = data['m'];

        if (pauseTrack)
            return

        if (qty < 1)
            return

        let level = trackingBook.find(_level => _level._id === price);

        if (level === undefined) {
            level = {_id: price, b: 0, a: 0};
            if (buy) {
                //sell
                level.a = qty;
            }
            else {
                level.b = qty;
            }
            setTrackingBook([...trackingBook, level])
        }
        // Update level
        else {
            setTrackingBook(
                trackingBook.map(level => {
                    if (level._id === price) {
                        if (buy)
                            level.a += qty
                        else
                            level.b += qty
                    }
                    return level
                })
            )
        }
        // console.log(trackingBook.length)
    }

    const ws_close = () => {
        ws.close()
    }

    // Start aggtrade if Tracking Order is enabled
    useEffect(() => {
        if (isTrackingEnabled) {
            /* eslint-disable */
            sub_aggtrade()
        }
    }, [isTrackingEnabled])

    // Symbol is set? Start ticker
    useEffect(async () => {
        if (symbol) {
            /* eslint-disable */
            sub_init()
        }
    }, [symbol])

    // Socket is open, process sub queue
    useEffect(async () => {
        if (isOpen) {
            // Subscription queue
            /* eslint-disable */
            const sub_queue = stream
            // console.log('onopen queue?:', sub_queue)
            if (sub_queue) {
                for (const key in sub_queue) {
                    const params = sub_queue[key]
                    if (params.exchange === false)
                        params.exchange = exchange
                    ws.send(JSON.stringify(params));
                }
            }
        }
    }, [isOpen])

    // Trick: keep onmsg with usestate updated
    useEffect(() => {
        if (ws) {
            /* eslint-disable */
            ws.onmessage = on_msg
        }
    }, [trackingBook, klineCallback])

    // Start websocket when exchange is set
    useEffect(async () => {
        if (exchange) {
            /* eslint-disable */
            const token = window.localStorage.getItem('token')
            const exchange_url = exchange_ws_url[exchange]
            ws = new WebSocket(`${WS_HOST}?t=${token}`)

            ws.onopen = () => {
                setIsOpen(true)
            }

            ws.onmessage = on_msg

            return () => { ws.close() }
        }

    }, [exchange])

    // FIXME
    // Not all needed to export, check
    return (
        <WebsocketContext.Provider value={{
            ticker,
            setTicker,
            trackingBook,
            setTrackingBook,
            isTrackingEnabled,
            setIsTrackingEnabled,
            exchange,
            setExchange,
            symbol,
            setSymbol,
            setKlineCallback,
            botId,
            symbolDecimal,
            setSymbolDecimal,
            pauseTrack,
            setPauseTrack,

            subscribe,
            unsubscribe,
            sub_kline,
            unsub_kline,
            sub_aggtrade,
            ws_close,
        }}>
            {props.children}
            <Outlet />
        </WebsocketContext.Provider>
    )
}

export default WebsocketProvider
