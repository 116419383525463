import {useEffect, useRef, useState} from "react";
import { widget } from './tv/charting_library';
// import Datafeed from './tv/datafeed'
import { useDatafeed } from "../../hooks/useDatafeed";

const TradingViewChart = props => {
    const tvRef = useRef()

    const dataFeed = useDatafeed()
    const [tvWidget, setTvWidget] = useState(null)

    useEffect(() => {
        if (props.botData) {
            // Bug TV resetting colors
	        localStorage["tradingview.chartproperties.mainSeriesProperties"] = "";

            const bot = props.botData
            const widgetOptions = {
                width: "100%",
                height: "100%",
                theme: "dark",
                symbol: bot.symbol, // default symbol
                // symbol: bot.symbol.replace('/', ''), // default symbol
                timezone: 'Etc/UTC',
                timeframe: '1D',
                //timeframe: '1D',
                interval: '30', // default interval
                fullscreen: false, // displays the chart in the fullscreen mode
                container: tvRef.current,
                datafeed: dataFeed,
                library_path: '/charting_library/',
                disabled_features: [
                    'header_saveload',
                    'header_compare',
                    'timeframes_toolbar',
                    'show_chart_property_page',
                    'header_symbol_search'
                ],
                enabled_features: [
                    'hide_left_toolbar_by_default',
                    'disable_resolution_rebuild',
                ],
                overrides:{
                    "timezone": 'Etc/UTC',
                    "mainSeriesProperties.style": 1,
                    "mainSeriesProperties.candleStyle.upColor": "#ffffff",
                    "mainSeriesProperties.candleStyle.downColor": "#e35864",
                    "mainSeriesProperties.candleStyle.drawWick": true,
                    "mainSeriesProperties.candleStyle.drawBorder": true,
                    "mainSeriesProperties.candleStyle.borderColor": "#cccccc",
                    "mainSeriesProperties.candleStyle.borderUpColor": "#cccccc",
                    "mainSeriesProperties.candleStyle.borderDownColor": "#e35864",
                    "mainSeriesProperties.candleStyle.wickUpColor": "#ffffff",
                    "mainSeriesProperties.candleStyle.wickDownColor": "#e35864",
                    "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
                }
            }

            /* eslint-disable */
            const tv_widget = new widget(widgetOptions);
            setTvWidget(tv_widget)
        }
    }, [props.botData])
    return(
        <div className="w-full min-h-[600px] md:h-full">
            <div ref={tvRef} className='w-full h-full'></div>
        </div>
    )
}

export default TradingViewChart;
