import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserProvider';

const Login = props => {
    const navigate = useNavigate()

    const { login, error, cleanError, isLogged } = useContext(UserContext)

    const [userData, setUserData] = useState({
        username: '',
        password: ''
    })

    const handleChange = (e) => {
            cleanError()

            setUserData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };
    
    // If user is logged, redirect to dashboard
    if (isLogged)
        return <Navigate to='/dashboard' />
    else
    return (
        <div className='m-auto'>
            <div className="card w-96 bg-slate-900 shadow-xl">
                <div className='card-body items-center p-10'>
                    <h2 className="card-title">
                        <FontAwesomeIcon icon={solid('user-lock')} className='mr-2' />
                        LOGIN
                    </h2>
                    <input
                        name="username"
                        type="text"
                        required
                        autoComplete="off"
                        placeholder="USERNAME"
                        className="input w-full my-2"
                        onChange={handleChange}
                    />
                    <input
                        name="password"
                        type="password"
                        required
                        placeholder="PASSWORD"
                        className="input w-full my-2"
                        onChange={handleChange}
                    />

                    { error?
                    <div className="alert shadow-lg bg-red-800">
                        <FontAwesomeIcon icon={solid('circle-xmark')} />
                        <div className='mx-auto'>{error}</div>
                    </div> : null }
                    
                    <div className='card-actions mt-5'>
                        <div className="btn btn-primary" onClick={() => login(userData)}>LOGIN</div>
                        <Link to="/register" className='mx-5'>
                            <div className="btn btn-secondary">REGISTER</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;