import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { UserContext } from '../../contexts/UserProvider'
import { useContext, useState } from 'react'
import { apiChangePassword } from '../../api/AccountAPI';

const UserProfileModal = (props, ref) => {

    const { account } = useContext(UserContext)
    const [error, setError] = useState(false)
    const [form, setForm] = useState({
        password: '',
        password_r: ''
    })

    const handleChange = (e) => {
        setError(false)
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    const changePassword = async () => {
        // If no password entered just close
        if (form.password === '' && form.password_r === '') {
            setError(false)
            closeModal()
            return
        }
        // Check password match
        if (form.password !== form.password_r) {
            setError('Please enter same password in both fields.')
            return
        }
        else {
            const res = await apiChangePassword(form.password)
            if (res) {
                closeModal()
            }
            else {
                setError("Please try again later.")
            }
        }


    }

    const closeModal = () => {
        ref.current.classList.remove('modal-open')
        setForm({
            password: '',
            password_r: ''
        })
    }

    return(
        <div className="modal" ref={ref}>
            <div className="modal-box">
                {/* TITLE */}
                <h3 className="font-bold text-lg">
                    <FontAwesomeIcon icon={solid('user-gear')} size="lg" /> PROFILE
                </h3>

                {/* CONTENT */}
                <div className='flex flex-col py-5 space-y-3 items-center'>
                    <h3>USERNAME</h3>
                    <input type="text" defaultValue={account.username} className='input input-bordered disabled' disabled={true} />
                    <h3>CHANGE PASSWORD</h3>
                    <input
                        onChange={handleChange}
                        value={form.password}
                        name="password"
                        type="password"
                        placeholder="PASSWORD"
                        className='input input-bordered'
                    />
                    <input
                        onChange={handleChange}
                        value={form.password_r}
                        name="password_r"
                        type="password"
                        placeholder="REPEAT PASSWORD"
                        className='input input-bordered'
                    />

                    <div className='w-fit'>
                        <label className='w-fit input-group h-8 mt-10'>
                            <span className="w-18 bg-secondary">VIP LEVEL</span>
                            <input type="text" defaultValue={account.is_vip?account.is_vip:"NONE"} disabled className='input input-bordered h-8 w-24' />
                            <div data-tip="More features on higher VIP levels" className='tooltip tooltip-secondary flex h-8 w-8 rounded-r-lg bg-gradient-to-br from-gray-600 to-gray-800 cursor-pointer'>
                                <FontAwesomeIcon icon={solid('circle-question')} size="1x" className='m-auto' />
                            </div>
                        </label>
                    </div>
                </div>
                    {error?
                    <div className="w-2/3 mx-auto my-8 alert shadow-lg alert-error">
                        <FontAwesomeIcon icon={solid('circle-xmark')} />
                        <div className='mx-auto text-white'>{error}</div>
                    </div> : null }


                {/* ACTIONS */}
                <div className="modal-action">
                    {/* eslint-disable-next-line */}
                    <div onClick={() => { changePassword() }} className="btn btn-primary">SAVE</div>
                    {/* eslint-disable-next-line */}
                    <div onClick={() => { closeModal() }} className="btn btn-secondary">CANCEL</div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(UserProfileModal);