

import Bar from '../components/bot/Bar'
import TradingViewChart from '../components/bot/TradingViewChart'
import TrackingOrder from '../components/bot/TrackingOrder'
import WallInfo from '../components/bot/WallInfo'
import { useParams } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'

import { getBotStatus, getExchangeInfo } from '../api/BotApi'

// import { useWebsocket } from '../hooks/useWebsocket'
import { WebsocketContext } from '../contexts/WebsocketProvider'

const Bot = props => {
    const { botId } = useParams()
    
    const {
        setSymbolDecimal,
        setExchange,
        setSymbol,
        ws_close,
    } = useContext(WebsocketContext)

    // Bot info
    const [botData, setBotData] = useState(false)

    // Show walls - tracking order on/off
    const [trackingVisible, setTrackingVisible] = useState(false)
    const [wallVisible, setWallVisible] = useState(true)

    // 
    const loadBotStatus = async () => {
        // Bot status
        const status = await getBotStatus(botId)

        // Start websocket
        setSymbol(status.symbol)
        setExchange(status.exchange)

        // Symbol info
        const symbol_info = await getExchangeInfo(status.exchange, status.symbol)
        status.symbol_info = symbol_info.data

        // Number of decimals to show with this symbol
        setSymbolDecimal(status.symbol_info.tick_size.split('.')[1].indexOf(1) + 1)

        // store bot status
        setBotData(status)

        return status
    }

    useEffect(() => {
        /* eslint-disable */
        loadBotStatus()

        return () => { ws_close() }
    }, [])

    const barProps = {
        trackingVisible,
        setTrackingVisible,
        wallVisible,
        setWallVisible,
        botData
    }
    
    return(
        <div className='flex flex-col w-full h-full'>
            <Bar {...barProps} />
            <div className="flex flex-col w-full h-[calc(100%-36px)] md:flex-row items-center">
                <TradingViewChart botData={botData} />
                <TrackingOrder show={trackingVisible} botId={botId} botData={botData} />
                <WallInfo show={wallVisible} botId={botId} botData={botData} />
            </div>
        </div>
    )
}

export default Bot;