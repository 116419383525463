export const WS_HOST = process.env.NODE_ENV === 'development'?
                            'ws://localhost:5555/ws':
                            `${window.location.protocol.replace("http", "ws")}//${window.location.host}/ws`

const API_HOST = process.env.NODE_ENV === 'development'?
                    'http://localhost:8000/api':
                    `${window.location.protocol}//${window.location.host}/api`

const getHeaders = () => {
    
    let headers = {
        'Content-Type': 'application/json'
    }
    
    const token = window.localStorage.getItem('token')
    if (token && token.length >= 40) {
        headers.Authorization = `Token ${token}`
    }
    return headers
}

export const api_get = async (path) => {
    const res = await fetch(`${API_HOST}${path}`,
    {
        method: 'GET',
        headers: getHeaders()
    })
    return res
}

export const api_post = async (path, data) => {
    const res = await fetch(`${API_HOST}${path}`,
    {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
    return res
}

export const api_delete = async (path, data) => {
    const res = await fetch(`${API_HOST}${path}`,
    {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
    return res
}