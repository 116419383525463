import './Dashboard.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { getBots, stopBot, getExchangeInfo } from '../api/BotApi';
import BotStartModal from '../components/dashboard/BotStartModal'

import { ExchangeLogoMatch } from '../asset/exchanges'
import { useEffect, useRef, useState } from 'react';
import ConfirmModal from '../components/ConfirmModal';

const Dashboard = props => {

    const [loading, setLoading] = useState(false)
    const [botList, setBotList] = useState([])
    const [deleteBotId, setDeleteBotId] = useState(null)
    const [exchangeInfo, setExchangeInfo] = useState([])

    const modalBotStop = useRef()
    const modalBotStart = useRef()
    
    const loadBots = async () => {
        setLoading(true)
        const bots = await getBots()
        const info = await getExchangeInfo()
        setExchangeInfo(info)
        setBotList(bots)
        // console.log('dashboard bots: ', bots)
        setLoading(false)
    }
    useEffect(() => {
        loadBots()
    }, [])

    const confirmStopBot = (e, id) => {
        e.preventDefault()
        modalBotStop.current.classList.add('modal-open')
        setDeleteBotId(id)
    }

    const confirmedStopBot = async () => {
        console.log(`api stop bot ${deleteBotId}`)

        // delete from botlist
        const bots = botList.filter(bot => {return bot.id !== deleteBotId })
        setBotList(bots)

        // Close modal
        modalBotStop.current.classList.remove('modal-open')

        // Stop bot API
        await stopBot(deleteBotId)
        setDeleteBotId(null) // Clean current id

        // Load bot list
        await loadBots()
    }

    const openBotStartModal = (e) => {
        modalBotStart.current.classList.add('modal-open')
    }

    return(
        <div className="flex flex-col w-full px-36 m-auto">
            {/* BOTS */}
            <div className='md:flex md:w-5/6 h-auto md:m-auto md:rounded-3xl md:overflow-hidden shadow-2xl'>
                {/* BOT TITLE BOX */}
                <div className='hidden md:flex md:bg-gradient-to-br md:from-foxblue-700 md:to-foxblue-900 md:w-48 md:h-40'>
                    <div className='md:flex md:flex-col md:m-auto md:text-foxblue-50 text-center'>
                        {loading?
                        <FontAwesomeIcon icon={solid('circle-notch')} size="3x"  className='my-auto animate-spin ' />
                        :
                        <FontAwesomeIcon icon={solid('robot')} size='4x' />
                        }
                        <span className='text-xl mt-3'>BOT</span>
                    </div>
                </div>

                {/* ADD BUTTON */}
                <div onClick={openBotStartModal} data-tip="START BOT" className='hidden md:flex md:flex-col md:w-8 md:h-40 md:bg-gray-900 md:text-gray-400 md:hover:bg-gray-700 md:hover:text-gray-200 md:cursor-pointer tooltip tooltip-right tooltip-secondary z-20'>
                    <FontAwesomeIcon icon={solid('plus')} size="2x" className="md:m-auto" />
                </div>

                {/* BOT HORIZONTAL LIST BOXES */}
                <div className='md:bg-slate-800 md:overflow-y-hidden md:flex md:w-full md:h-40'>
                    {/* BOT BOX */}
                    { botList.map(bot => (
                    <Link to={`/bot/${bot.id}`} key={bot.id}>
                        <div className="flex flex-col text-gray-400 items-center w-36 h-fit relative bg-gray-900 bg-opacity-50 rounded-xl p-5 px-10 border border-secondary hover:border-accent m-4 md:hover:bg-gradient-to-br md:hover:from-gray-800 md:hover:to-gray-900 shadow-xl">
                            <div onClick={(e) => { confirmStopBot(e, bot.id) }} data-tip="DELETE BOT" className="absolute top-3 right-3 tooltip tooltip-secondary">
                                <FontAwesomeIcon icon={solid('circle-xmark')} size="xs" className='w-4 h-4 hover:text-gray-300' />
                            </div>
                            <div className='flex flex-col h-[82px] text-center'>
                                <FontAwesomeIcon icon={solid('cog')} size="2x" className='animate-spin-slow' />
                                <div className='mt-1'>
                                    <span className='text-gray-200 mr-1'>{bot.symbol}</span>
                                </div>
                                <span className='text-foxblue-500 text-xs'>{bot.size}</span>
                            </div>
                            <div className='absolute w-full bottom-2'>
                                <div className='flex w-fit mx-auto'>
                                    <img src={ExchangeLogoMatch[bot.exchange]} alt='exchange' className="w-4 h-4 rounded-full mx-[2px]" />
                                </div>
                            </div>
                        </div>
                    </Link>
                    ))
                    }
                
                </div>
            </div>
            
            {/* START BOT MODAL */}
            <BotStartModal id="modal-start-bot" ref={modalBotStart} exchangeInfo={exchangeInfo} setBotList={setBotList} setLoading={setLoading} />
            {/* DELETE BOT CONFIRM MODAL */}
            <ConfirmModal id="modal-stop-bot" ref={modalBotStop} onConfirm={() => { confirmedStopBot() }} title="STOP BOT" message="Are you sure to stop this bot?" />

        </div>
    )
}

export default Dashboard;