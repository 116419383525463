import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const ConfirmModal = (props, ref) => {
    const closeModal = () => {
        ref.current.classList.remove('modal-open')
    }
    return (
        <div className="modal" id="modal-confirm" ref={ref}>
            <div className="modal-box">
                <h3 className="font-bold text-lg">{props.title? props.title:"CONFIRM"}</h3>

                <div className="flex flex-col mt-4 py-4 mx-auto w-fit space-y-2">
                    {props.message}
                </div>
                <div className="modal-action">
                    {/* eslint-disable-next-line */}
                    <a onClick={() => { props.onConfirm() }} className="btn btn-primary">CONFIRM</a>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {closeModal()}} className="btn btn-secondary">CANCEL</a>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(ConfirmModal);