import { api_get, api_post, api_delete } from './request'

// All user's bot
export const getBots = async () => {
    const res = await api_get('/bot/')

    if (res.ok) {
        const data = await res.json()
        return data
    }
    else {
        console.log('api/bot error: ',res)
        return []
    }
}

// Start new bot
export const startBot = async (params) => {
    const { exchange, symbol, size } = params
    console.log(params)
    if (!exchange || !symbol || !size) {
        return false
    }

    const res = await api_post('/bot/start/', params)
    if (res.ok) {
        const data = await res.json()
        return data
    }
}

// Stop existing bot
export const stopBot = async (id) => {
    const res = await api_get(`/bot/${id}/stop/`)

    if (res.ok) {
        console.log('Bot stopped')
    }
}

// Get bot status
export const getBotStatus = async (id) => {
    const res = await api_get(`/bot/${id}/status/`)

    if (res.ok) {
        const data = await res.json()
        return data
    }
}

// Get order tracking book
export const getBotOrderTrackingBook= async (id, filter_time=5, filter_date=null) => {
    const filters = {
        '': 0,
        '5m': 5,
        '30m': 30,
        '1H': 60,
        '2H': 120,
        '4H': 240,
        '6H': 360,
        '12H': 720,
        '1D': 1440
    }
    if (typeof filter_time === 'string') {
        filter_time = filters[filter_time]
    }
    if (isNaN(filter_time)) return

    const filterDate = filter_date===null? "":filter_date

    const res = await api_get(`/bot/${id}/get_order_tracking_book/${filter_time}/${filterDate}`)

    if (res.ok) {
        const data = await res.json()
        return data
    }
}

// TRACKING ORDER: toggle
export const toggleTrackingOrder = async (id) => {
    const res = await api_post(`/bot/${id}/order_tracking_toggle/`)

    if (res.ok) {
        const data = await res.json()
        return data['status']
    }
}

// TRACKING ORDER: clean
export const cleanTrackingOrder = async (id) => {
    const res = await api_post(`/bot/${id}/order_tracking_clean/`)

    if (res.ok) {
        const data = await res.json()
        return data['status']
    }
}

// TradingView datasets
export const getBotMarks = async (id, resolution, from, to) => {
    if (resolution === '1D')
		resolution = 1440;
	if (resolution === '1W')
		resolution = 10080;

    const params = {
        resolution: resolution,
        from_ts: from,
        to_ts: to
    }
    const res = await api_post(`/bot/${id}/marks/`, params)

    if (res.ok) {
        const data = res.json()
        return data
    }
}

// WALLS: Download filled walls
export const filledDownload = async (id) => {
    const res = await api_get(`/bot/${id}/download_history/`)

    if (res.ok) {
        const data = await res.blob()
        return data
    }
}

// WALLS: Delete filled walls
export const filledDelete = async (id) => {
    const res = await api_post(`/bot/${id}/delete_history/`)

    if (res.ok)
        return true
    else
        return false
}

// EXCHANGE info
export const getExchangeInfo = async(exchange='', symbol='') => {
    const res = await api_get(`/exchange_info/?exchange=${exchange}&symbol=${symbol}`)

    if (res.ok)
    {
        const data = await res.json()
        return data
    }
    else
        return false
}

// TRACKING ORDER: Create alarm
export const createOrderTrackingAlarm = async (id, time, bid, ask) => {
    const params = {
        bot_id: id,
        time: time,
        bid: bid>1? bid : null,
        ask: ask>1? ask : null
    }
    const res = await api_post(`/bot/order_tracking_alert/`, params)

    if (res.ok)
        return true
    else
        return false
}

// TRACKING ORDER: Delete alarm
export const deleteOrderTrackingAlarm = async (bot_id) => {
    const params = {
        bot_id: bot_id
    }
    const res = await api_delete(`/bot/order_tracking_alert/`, params)

    if (res.ok)
        return true
    else
        return false
}