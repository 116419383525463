import React from 'react';
import { useRef, useState, useEffect, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Combobox, Transition } from '@headlessui/react'

import './BotStartModal.css'
import {
    BinanceLogo,
    BinanceFuturesLogo,
    BithumbLogo,
    BitstampLogo,
    CoinbaseLogo,
    FtxLogo,
    HuobiLogo
} from '../../asset/exchanges'
import { startBot } from '../../api/BotApi'

const BotStartModal = (props, ref) => {

    const {
        id,
        setLoading,
        setBotList,
        exchangeInfo
    } = props

    const botCoinRef = useRef()
    const botUsdtRef = useRef()
    const sizeUsdtRef = useRef()

    const [error, setError] = useState(false)
    const [exchangeData, setExchangeData] = useState([])
    const [selectedSymbol, setSelectedSymbol] = useState('')
    const [selectedSymbolInfo, setSelectedSymbolInfo] = useState('')
    const [botSizeCoin, setBotSizeCoin] = useState(0)
    const [botSizeUsdt, setBotSizeUsdt] = useState(0)
    const [query, setQuery] = useState('')
    const [botData, setBotData] = useState({
        exchange: 'BINANCE',
        symbol: '',
        size: ''
    })

    const cleanForm = () => {
        setError(false)
        setBotData({
            exchange: 'BINANCE',
            symbol: '',
            size: '',
        })
        setSelectedSymbol("")
    }

    const handleChange = (e) => {
        setError(false)
        setBotData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };
    const handleSize = (e, size, usdt=false) => {
        setError(false)
        let botSize = 0
        let amount = 0
        let botSizeStart = 0;

        if (!usdt) {
            botSize = e.target.value
            setBotSizeCoin(botSize)
            if (selectedSymbolInfo.qasset === 'USDT') {
                amount = botSize * selectedSymbolInfo.price
            }
            else if(selectedSymbolInfo.qasset === 'BTC') {
                // Search for BTCUSDT price then calc
                const btc = exchangeData.find(s => s.symbol === 'BTCUSDT')
                amount = botSize * btc.price * selectedSymbolInfo.price
            }

            setBotSizeUsdt(amount.toFixed(2))
            botSizeStart = botSize
        } else {
            botSize = e.target.value
            setBotSizeUsdt(botSize)

            if (selectedSymbolInfo.qasset === 'USDT') {
                amount = botSize / selectedSymbolInfo.price
            }
            else if(selectedSymbolInfo.qasset === 'BTC') {
                // Search for BTCUSDT price then calc
                const btc = exchangeData.find(s => s.symbol === 'BTCUSDT')
                amount = botSize / btc.price / selectedSymbolInfo.price
            }
            setBotSizeCoin(amount.toFixed(2))
            botSizeStart = amount.toFixed(2)
        }

        setBotData((prev) => ({
            ...prev,
            size: botSizeStart
        }));
    }

    const startBotSubmit = async () => {
        // Check all fields
        const { symbol, size } = botData
        console.log(botData)
        if (!symbol || !size) {
            setError('All fileds are required')
            return
        }
        setLoading(true)
        ref.current.classList.remove('modal-open')
        const bot = await startBot(botData)
        console.log(bot)
        setBotList((prev) => ([
            ...prev,
            bot
        ]));
        cleanForm()
        setLoading(false)
    }

    const closeModal = () => {
        ref.current.classList.remove('modal-open')
        cleanForm()
    }

    /* eslint-disable */
    useEffect(() => {
        exchangeInfo.filter(data => {
            if (data.exchange === botData.exchange)
                setExchangeData(data.data)
        })

    }, [exchangeInfo, botData])  // Both because form exchange radio

    useEffect(() => {
        if (selectedSymbol !== '') {
            const symbol_info = exchangeData.filter((data) => data.symbol === selectedSymbol)
            console.log(symbol_info)
            setSelectedSymbolInfo(symbol_info[0])
            botData.symbol = selectedSymbol
        }
    }, [selectedSymbol])

    const filteredSymbol =
        query === ''
        ? exchangeData
        : exchangeData.filter((data) => {
            return data.symbol.toLowerCase().startsWith(query.toLowerCase())
            })

    return (
        <div className="modal" id={id} ref={ref}>
            <div className="modal-box">
                <h3 className="font-bold text-lg">START BOT</h3>

                {/* EXCHANGE RADIO */}
                <div className="flex flex-col py-4">
                    <h2 className='mx-auto'>EXCHANGE</h2>
                    <div className="flex m-auto mt-3">
                        <label data-tip="Binance" className="tooltip tooltip-bottom tooltip-secondary">
                            <input onChange={handleChange} type="radio" name="exchange" value="BINANCE" className="hidden peer" checked={botData.exchange === 'BINANCE'} />
                            <img src={BinanceLogo} alt="Binance" className="ex_img select_effect" />
                        </label>
                        <label data-tip="Binance USD(m)" className="tooltip tooltip-bottom tooltip-secondary">
                            <input onChange={handleChange} type="radio" name="exchange" value="BINANCE_FUTURES" className="hidden peer" checked={botData.exchange === 'BINANCE_FUTURES'} />
                            <img src={BinanceFuturesLogo} alt="Binance Futures" className="ex_img select_effect" />
                        </label>
                        <label data-tip="FTX" className="tooltip tooltip-bottom tooltip-secondary">
                            <input onChange={handleChange} type="radio" name="exchange" value="3" disabled className="hidden peer" checked={botData.exchange === '3'} />
                            <img src={FtxLogo} alt="FTX" className="ex_img select_effect" />
                        </label>
                        <label data-tip="Bitstamp" className="tooltip tooltip-bottom tooltip-secondary">
                            <input onChange={handleChange} type="radio" name="exchange" value="4" disabled className="hidden peer" checked={botData.exchange === '4'} />
                            <img src={BitstampLogo} alt="Bitstamp" className="ex_img select_effect" />
                        </label>
                        <label data-tip="Huobi" className="tooltip tooltip-bottom tooltip-secondary">
                            <input onChange={handleChange} type="radio" name="exchange" value="5" disabled className="hidden peer" checked={botData.exchange === '5'} />
                            <img src={HuobiLogo} alt="Huobi" className="ex_img select_effect" />
                        </label>
                        <label data-tip="Coinbase" className="tooltip tooltip-bottom tooltip-secondary">
                            <input onChange={handleChange} type="radio" name="exchange" value="6" disabled className="hidden peer" checked={botData.exchange === '6'} />
                            <img src={CoinbaseLogo} alt="Coinbase" className="ex_img select_effect" />
                        </label>
                        <label data-tip="Bithumb" className="tooltip tooltip-bottom tooltip-secondary">
                            <input onChange={handleChange} type="radio" name="exchange" value="7" disabled className="hidden peer" checked={botData.exchange === '7'} />
                            <img src={BithumbLogo} alt="Bithumb" className="ex_img select_effect" />
                        </label>
                    </div>
                </div>

                <div className="flex flex-col mt-5 pb-4 mx-auto w-fit">

                    {/* SYMBOL */}
                    <div className="w-[345px] space-y-5">
                        <Combobox value={selectedSymbol} onChange={setSelectedSymbol}>
                            <div className="relative mb-10">
                            <div className="relative flex w-full text-left shadow-md sm:text-sm ">
                                <div className='bg-secondary p-3 w-24 rounded-l-lg'>
                                    <span className='m-auto'>SYMBOL</span>
                                </div>
                                <Combobox.Input
                                    className="w-4/5 bg-base-100 py-2 pl-3 pr-10 text-sm leading-5 rounded-l-none input input-bordered focus:outline-none focus:border-primary"
                                    displayValue={(data) => data}
                                    placeholder="BTCUSDT"
                                    autoComplete='off'
                                    onChange={(event) => setQuery(event.target.value)}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery('')}
                            >
                                <Combobox.Options className="absolute z-10 w-[260px] ml-[85px] max-h-[148px] py-1 mt-1 overflow-auto text-base bg-secondary rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredSymbol.length === 0 && query !== '' ? (
                                    <div className="cursor-default select-none relative py-2 px-4 text-gray-500 italic">
                                    Nothing found.
                                    </div>
                                ) : (
                                    filteredSymbol.map((data) => (
                                    <Combobox.Option
                                        key={data.symbol}
                                        className={({ active }) =>
                                        `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                            active ? 'text-white bg-secondary-focus' : 'text-bg-accent-content'
                                        }`
                                        }
                                        value={data.symbol}
                                    >
                                        {({ selected, active }) => (
                                        <>
                                            <span
                                            className={`block truncate ${
                                                selected ? 'font-medium' : 'font-normal'
                                            }`}
                                            >
                                            {data.symbol}
                                            </span>
                                        </>
                                        )}
                                    </Combobox.Option>
                                    ))
                                )}
                                </Combobox.Options>
                            </Transition>
                            </div>
                        </Combobox>
                    </div>

                    {/* SIZE */}
                    <label className="input-group input-group mb-3">
                        <span className="w-24 bg-secondary">SIZE</span>
                        <input
                            ref={botCoinRef}
                            onClick={(e) => { e.target.select() } }
                            onChange={(e) => { handleSize(e, botSizeCoin) } }
                            value={botSizeCoin}
                            name="size"
                            type={selectedSymbol===''?"text":"number"}
                            min="0"
                            placeholder="0"
                            className="input input-bordered focus:outline-none focus:border-primary"
                            disabled={selectedSymbol === ''? true:false} />
                    </label>

                    {/* USDT */}
                    <label className="input-group input-group">
                        <span className="w-24 bg-secondary">USDT</span>
                        <input
                            ref={botUsdtRef}
                            onClick={(e) => { e.target.select() } }
                            onChange={(e) => { handleSize(e, botSizeUsdt, true) } }
                            value={botSizeUsdt}
                            name="size"
                            type={selectedSymbol===''?"text":"number"}
                            min="0"
                            placeholder="0"
                            className="input input-bordered focus:outline-none focus:border-primary"
                            disabled={selectedSymbol === ''? true:false} />
                    </label>
                    
                    <div className={`flex relative ${botSizeUsdt===0 || botSizeUsdt>=200000?"hidden":null}`}>
                        <div className='absolute flex right-[65px] text-xs italic text-foxred-500'>
                            <div className='flex mr-2'>
                                <span>Minimum size 200,000 USDT</span>
                                {/*<span ref={sizeUsdtRef}>0</span>*/}
                                {/*<span> &nbsp;USDT</span>*/}
                            </div>
                            <div data-tip="Minimum size of bot must be equivalent to 200,000 USDT or more." className='tooltip tooltip-left cursor-pointer'>
                                <FontAwesomeIcon icon={solid('circle-question')} size="1x" />
                            </div>
                        </div>
                    </div>

                    {error?
                    <div className="mt-8 alert shadow-lg alert-error">
                        <FontAwesomeIcon icon={solid('circle-xmark')} />
                        <div className='mx-auto'>{error}</div>
                    </div> : null }
                </div>
                <div className="modal-action">
                    <div onClick={startBotSubmit} className={`btn ${botSizeUsdt>=200000? 'btn-primary': 'btn-disabled'}`}>START</div>
                    <div onClick={closeModal} className="btn btn-secondary">CANCEL</div>
                </div>

            </div>
        </div>
    )
}

export default React.forwardRef(BotStartModal);