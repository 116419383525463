import { Navigate, Outlet } from "react-router-dom";

//  Route Protected
const AuthRequired = () => {

    //const {token, setToken} = useContext(UserContext)

    let token = window.localStorage.getItem('token')

    if (token === '' || token === undefined) {
        token = false
    }

    //  Validate
    return (
        token?
            <Outlet />
            : <Navigate to="/login" replace />
    )
}

export default AuthRequired;
