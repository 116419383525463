import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiGetAccount } from "../api/AccountAPI";
import { api_post } from "../api/request"

export const UserContext = createContext()

const UserProvider = props => {

    const navigate = useNavigate()

    const [error, setError] = useState(false)
    const [account, setAccount] = useState([])
    const [token, setToken] = useState(false)
    const [isLogged, setIsLogged] = useState(false)

    const cleanError = async () => { setError(false) }

    const login = async (params) => {
        setError(false)

        // We don't submit without two fields
        const { username, password } = params
        if (!username || !password)
            return
        
        const res = await api_post('/auth/', params)

        if (res.ok) {
            const data = await res.json()
            window.localStorage.setItem('token', data['token'])
            window.localStorage.setItem('account', JSON.stringify(data['account']))
            setAccount(data['account'])
            setIsLogged(true)

            navigate('/dashboard')
        }
        else if (res.status === 400)
            setError('Invalid credentials')
        else
            setError('Login not available')
    }

    const logout = async () => {
        window.localStorage.removeItem('account')
        window.localStorage.removeItem('token')
        setIsLogged(false)
        navigate('/login')
    }

    const register = async (params) => {
        const { username, password, password_r } = params

        // Require all fields
        if (!username || !password || !password_r) {
            setError('Please complete all fields')
            return
        }

        // match passwords
        if (password !== password_r) {
            setError('Passwords does not match')
            return
        }

        const res = await api_post('/account/register/', params)

        if (res.ok)
            navigate('/login')
        else
            setError('Registration is not available')
    }

    const checkLogged = async () => {
        // check current user
        const user = await apiGetAccount()
        if (user) {
            window.localStorage.setItem('account', JSON.stringify(user))
            setAccount(user)
            setIsLogged(true)
        }
        else {
            setIsLogged(false)
            navigate('/login')
        }
    }

    useEffect(() => {
        checkLogged()
    }, [])

    return (
        <UserContext.Provider value={{
            account,
            token,
            setToken,
            error,
            cleanError,
            isLogged,
            login,
            logout,
            register,
            checkLogged
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider;